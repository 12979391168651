<template>
    <div class="settings">
        <van-nav-bar class="bar" :border="false" :title="$t('safety.text1')" left-arrow @click-left="onClickLeft" />
        <div style="height: 12px;"></div>
        <van-cell-group class="cell-group" :border="false">
            <van-field class="settings-cell" :border="false" type="password" v-model="old_password" :placeholder="$t('safety.text2')" v-if="user.has_pay_password" />
            <van-field class="settings-cell" :border="false" type="password" v-model="new_password" :placeholder="$t('safety.text3')" />
            <van-field class="settings-cell" :border="false" type="password" v-model="confirm_password" :placeholder="$t('safety.text4')" />
        </van-cell-group>

        <div class="login-password__btn">
            <van-button type="primary" color="linear-gradient( 270deg, #A44AFB 0%, #F85DC6 100%)" block round @click="onSubmit">{{ $t('login.submit') }}</van-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { updatePayPassword } from "@/api";

export default {
    data() {
        return {
            old_password: '',
            new_password: '',
            confirm_password: ''
        };
    },
    computed: {
        ...mapState(["user"]),
    },
    methods: {
        ...mapActions(['fetchUser']),
        onClickLeft() {
            this.$router.replace({ path: '/my' });
        },
        onSubmit() {
            if (this.user.has_pay_password && !this.old_password) {
                this.$toast(this.$t('safety.text2'));
                return;
            }
            if (!this.new_password) {
                this.$toast(this.$t('safety.text3'));
                return;
            }
            if (this.new_password !== this.confirm_password) {
                this.$toast(this.$t('register.toast1'));
                return;
            }
            updatePayPassword({
                old_pay_password: this.old_password,
                pay_password: this.confirm_password,
            }).then(({ code }) => {
                if (code === 200) {
                    this.fetchUser();
                    this.$toast(this.$t('safety.modified_successfully'));
                    setTimeout(() => {
                        this.$router.back();
                    }, 1500)
                } else {
                    this.$toast(this.$t('safety.old_password_error'));
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.login-password__btn {
    padding: 24px;
}
.bar /deep/ .van-nav-bar__left .van-icon {
    color: var(--font-color);
}
.bar /deep/ .van-nav-bar__title {
    color: var(--font-color);
}
.settings {
    min-height: 100vh;
    background: url(../../../assets/login_bg.png) left top no-repeat
        var(--bg-color);
    background-size: 100%;
    .bar {
        background: none;
    }
    .cell-group {
        background: none;
    }
    .settings-cell {
        margin: 14px 16px;
        width: 343px;
        height: 48px;
        background: #ffffff;
        border-radius: 23px 23px 23px 23px;
        color: #333;
    }
}
@media only screen and (min-width: 750px) {
    .settings {
        background: none;
        padding: 42px;
        .cell-group {
            width: 58.3%;
            margin: 50px auto;
            .settings-cell {
                margin: 20px 0;
                width: 100%;
                height: 78px;
                background: #f0f3f8;
                border-radius: 16px;
                font-size: 16px;
                color: #666666;
                padding: 0 25px;
                /deep/ .van-field__control {
                    height: 78px;
                }
            }
        }
        .login-password__btn {
            padding: 0;
            display: flex;
            justify-content: center;
            .van-button {
                width: 319px;
                height: 60px;
                line-height: 60px;
                background: linear-gradient(270deg, #a44afb 0%, #f85dc6 100%);
                border-radius: 55px 55px 55px 55px;
                font-size: 16px;
                color: #ffffff;
            }
        }
        .bar {
            line-height: 30px;
            z-index: 0;
            /deep/ .van-nav-bar__content {
                height: 30px;
            }
            /deep/ .van-nav-bar__left {
                padding: 0;
                .van-icon {
                    font-size: 28px;
                }
            }
            /deep/ .van-nav-bar__title {
                font-size: 28px;
            }
        }
    }
}
</style>
